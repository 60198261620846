// 下载普通文件服务类型
<template>
<div>
   <!-- <el-tooltip  effect="dark" :content="$attrs.tipTxt" placement="top"> -->
     <base-button  class="formBtn" v-bind="$attrs"  @click.native="download" />
   <!-- </el-tooltip> -->

</div>
</template>

<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'

export default {
  name: 'downLoadBtn',
  components: { BaseButton },
  props: {},
  computed: {
    file () {
      return this.$attrs
    }
  },
  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    download () {
      console.log(this.$attrs, 'this.$attrs')
      if (this.$attrs.fileId && this.$attrs.fileId !== '0') {
        downAPi
          .download({ keyId: this.$attrs.fileId })
          .then((res) => {
            downFile(res)
          })
          // .catch((e) => {
          //   this.error(e)
          // })
      } else {
        this.warning('暂无文件')
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.formBtn{
  padding: 0;
  text-align: center;
  width: 30px;
  height: 30px;
  display: block;
  border-radius:50% ;
  /deep/.iconfont {
    margin: 0 !important;
  }
}
</style>
